export function youtubeParser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  var match = url?.match(regExp)
  return match && match[7].length === 11 ? match[7] : false
}

export function vimeoParser(url) {
  const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
  const parseUrl = regExp.exec(url)
  return parseUrl && parseUrl[5]
}
