import React from "react"
import { graphql } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { VideoIframe } from "../UI/VideoIframe"
import { PillButton } from "../UI/PillButton"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment textBoxAndVidOrImgFragment on WpPage_Flexlayouts_FlexibleLayouts_TextBoxAndVidOrImg {
    sectionHeading
    headingType
    textBoxHeading
    text
    button {
      title
      url
    }
    videoOrImage {
      image {
        ...GatsbyImageQuery
      }
      video
    }
    sideMargin
  }
`

const TextBoxAndVidOrImg = ({
  sectionHeading,
  headingType,
  textBoxHeading,
  text,
  button,
  videoOrImage,
  sideMargin
}) => {
  const photo = getImage(videoOrImage[0]?.image?.localFile)
  const alt = videoOrImage[0]?.image?.altText

  return (
    <section className={sideMargin ? "bg-pale-grey p-0" : ""}>
      <Container className={sideMargin ? "bg-white p-7" : ""}>
        <SectionHeading type={headingType} text={sectionHeading} className="pb-3"/>        
        <Row>
          <Col xs={12} lg={5} className="mb-4 mb-lg-0">
            <div
              className="p-4 py-lg-5"
              style={{ borderRadius: "20px", backgroundColor: "#f7edf3" }}>
              <h3 dangerouslySetInnerHTML={{ __html: textBoxHeading }} />
              <p
                className="py-lg-3"
                dangerouslySetInnerHTML={{ __html: text }}
              />
              {button && <PillButton
                link={button.url}
                title={button.title}
              />}
            </div>
          </Col>
          <Col xs={12} lg={7}>
            {videoOrImage[0].video ? (
              <VideoIframe video={videoOrImage[0].video} />
            ) : (
              <GatsbyImage image={photo} alt={alt} className="w-100 h-100" />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TextBoxAndVidOrImg