import React from "react"
import { youtubeParser, vimeoParser } from "../../utils/youtubeParser"
import { videoBox } from "./VideoIframe.module.scss"

export const VideoIframe = ({ video }) => {
  const youtubeVideoId = youtubeParser(video)
  if (youtubeVideoId !== false) {
    return (
      <div className={videoBox}>
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src={`https://www.youtube.com/embed/${youtubeVideoId}`}
          frameBorder="0"
        />
      </div>
    )
  } else {
    return (
      <div className={videoBox}>
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src={`https://player.vimeo.com/video/${vimeoParser(video)}`}
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
    )
  }
}
